import React, { useEffect } from 'react'
import './App.css'
import AOS from "aos";
import "aos/dist/aos.css";
import Home from 'pages/Home'
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route
// } from "react-router-dom";
function App() {

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div className="">
      {/* <Router>
        <Switch>
          <Route path={"/"} component={Home} />
        </Switch>
      </Router> */}
      <Home /> 

    </div>
  )
}

export default App
