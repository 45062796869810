import Hero from 'components/Hero'
import React from 'react'

import Header from 'layout/Header'
import Features from 'components/Features'
import AboutUs from 'components/AboutUs'
import Slider from 'components/Slider'
import VideoSection from 'components/VideoSection'
import ContactUs from 'components/ContactUs'



function Home() {
    return (
        <div >
            <Header />

            <Hero  />

            <Features />

            <AboutUs />
     
            <Slider />

            <VideoSection />
        
            <ContactUs />

            
        </div>
    )
}

export default Home
