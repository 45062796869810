import React from 'react'
import Video from './Video'

function VideoSection() {
  return (
    <div className='p-20 bg-footy flex flex-col items-center' >
         <Video src={"videos/video.mp4"} poster={"videos/poster_.png"} />
      
    </div>
   
  )
}

export default VideoSection