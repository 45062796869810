import React from 'react'

function ContactUs() {
  return (     
    <div data-aos="fade-up" className="py-8 bg-footy">
        <div className=' max-w-screen-xl px-4 mx-auto'>
        <div className='grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-4 py-8 '>
            <div className=''>

               
                <div>
                    <img src="images/contact_us.png" className='px-20 ' alt="" srcset="" />
                </div>
                <div className='py-4'>
                    <h1 className='text-white text-2xl uppercase'>Installez votre application GRATUITEMENT et commencez votre match dès maintenant !</h1>

                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <button className='py-4  rounded-full font-bold bg-secondary text-white'>
                        Télécharger sur Android
                    </button>
                    <button className='py-4  rounded-full font-bold bg-secondary text-white'>

                        Télécharger sur IOS
                    </button>
                </div>
               
            </div>
            <div className=''>

                <div className='p-4 bg-white  rounded-md text-black text-opacity-90'>
                    <h1 className="py-6 text-center text-3xl capitalize  font-bold">contact footy find</h1>
                    {/*  form  */}
                    <div className="form ">
                        <div className="grid grid-cols-2 gap-2 px-2">
                            <div className="border  rounded-xl">
                                <input type="text" placeholder="first name" className="bg-transparent  w-full  outline-none p-2 " />
                            </div>
                            <div className="border  rounded-xl ">
                                <input type="text" placeholder="last name" className="bg-transparent  w-full  outline-none p-2 " />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2 px-2 py-4">
                            <div className=" border  rounded-xl  ">
                                <input type="text" placeholder="Email" className="bg-transparent  w-full  outline-none p-2 " />
                            </div>
                            <div className=" border  rounded-xl ">
                                <input type="text" placeholder="Phone Number" className="bg-transparent  w-full  outline-none p-2 " />
                            </div>
                        </div>
                        <div className="flex w-full gap-2 p-2">
                            <div className=" border w-full rounded-xl ">
                                <textarea type="text" placeholder="Message" className="bg-transparent  w-full outline-none p-2 " />
                            </div>
                        </div>
                        <div className="flex justify-center  pt-4">
                            <button className="text-white bg-primary uppercase rounded-3xl px-6 py-3">
                                Submit your message
                            </button>

                        </div>



                    </div>
                </div>
                
            </div>
         </div>
        </div>
        <div className='text-white text-center text-xs text-opacity-60'>
            FootyFind @ 2022
        </div>

    </div>
  )
}

export default ContactUs