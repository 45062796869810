import React, { useState } from 'react'

const data = [
    {id:1,title:"Qui sommes-nous ?",desciption:"Application mobile morocaine qui rassemble les amateurs de football. Une plateforme qui vous permet un accès facile et rapide pour pratiquer votre sport préféré, le foot."},
    {id:2,title:"Pourquoi nous choisir ?",desciption:"En plus d'être la première et la seule Application au Maroc, FootyFind sera votre meilleure alliée pour organiser et trouver facilement des matchs à proximité de chez vous, trouver les joueurs manquants à vos équipes, participer à des tournois, gagner des prix et bien plus encore !"},
    {id:3,title:"Notre mission !",desciption:" Notre application est conçue par des Marocains pour les Marocains, nous avons relevé le défi de créer une plateforme de qualité qui rassemble tous les fans de football en un seul endroit. En rendant ce sport plus accessible, vous pouvez désormais participer à n'importe quel match de football, n'importe où, n'importe quand et avec l'équipe de votre choix. Et ce n'est que le début de notre aventure..."},


]
const Item=({item,id,activeIndex,setActiveIndex}) => {
    
    return (
        <div  className='pt-6'>
                <div onClick={()=> setActiveIndex(item.id)} className='cursor-pointer flex items-center'>
                    <div className={`w-10 h-10 ${item.id === activeIndex ?"bg-white  text-secondary" :"border-white text-white "}   flex items-center justify-center border  rounded-full`} >
                        {id}
                    </div>
                    <div className='text-xl font-bold pl-4 '>
                       {item.title}
                    </div>
                </div>
                <div  className={`pl-14 animate-content  ${activeIndex === item.id ? "block fadeRight":"hidden "} `}>
                  
                    <div>
                        <p className='p'>
                            {item.desciption}
                        </p>
                    
                    </div>
                </div>
            
                
            </div>
    )
}


function AboutUs() {
    const [activeIndex, setActiveIndex] = useState(1)

  return (
    <div  data-aos="fade-left"  className=' p-8 py-16  bg-cover bg-center bg-no-repeat ' style={{backgroundImage:'url(/bg2.png)'}}>
        <div className='max-w-screen-md mx-auto'>
            <h1 className='text-4xl text-center font-bold'> Apprenons à nous connaître !</h1>
            <p className='py-4'>
                Vous avez envie de jouer un match ? Fini la galère de trouver un terrai disponible et rassembler des joueurs
            </p>
      

            {data.map((item,id) => {
                return <Item key={id} activeIndex={activeIndex} setActiveIndex={setActiveIndex} item={item} id={id+1}/> 
            })}



            <img src="about_us.png" className='w-full ' alt="" srcset="" />

        </div>
    </div>
  )
}

export default AboutUs