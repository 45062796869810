import React from 'react'

function Header() {
    return (
        <div className="absolute px-4 top-0 right-0 left-0  py-2">
        <div className="p-2  flex justify-between items-center">
            <div className="flex items-center">
                <img src="logo.png" width={"50px"} alt="" />
                <div className='text-white pl-4 text-3xl font-extrabold'>
                    FOOTY FIND
                </div>
                <div style={{width:".5px"}} className="h-8 mx-4 bg-gray-600">

            </div>

            </div>
       
            {/* <div>
                <button className="bg-white py-1 rounded-full px-8">
                    App
                </button>
            </div> */}
            
        </div>

    </div>
    )
}

export default Header
