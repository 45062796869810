import React, { useState } from 'react'

const Feature = ({icon,title}) => (
    <div className='flex py-3 items-center md:text-2xl lg:text-4xl font-bold text-white'>
        <img src={`icons/${icon}.svg`} className='w-10 lg:w-18 ' alt="" srcset="" />
        <div className='pl-3'>
            {title}
        </div>
    </div>
)

function Features() {

    const [activetab, setActivetab] = useState(0)
    
    const handleTabChange = (id)=> (ev)=> {
        setActivetab(id)
    }

    return (
        <div   className='p-8 py-16 bg-footy '>
        <div className='flex justify-center items-center gap-3'>
            <button onClick={handleTabChange(0)} className={`px-8 text-xl font-bold py-3 ${activetab === 0 ? "bg-secondary text-white" : "text-white border border-white"}  rounded-full`}>
                Créez
            </button>
            <button onClick={handleTabChange(1)} className={`px-8 text-xl font-bold py-3 ${activetab === 1 ? "bg-secondary text-white" : "text-white border border-white"}  rounded-full`}>
                Jouez
            </button>
            <button onClick={handleTabChange(2)} className={`px-8 text-xl font-bold py-3 ${activetab === 2 ? "bg-secondary text-white" : "text-white border border-white"}  rounded-full`}>
                Gagnez
            </button>
        </div>

        <div className={activetab ===0? 'pt-10 grid md:grid-cols-3 ':'hidden'}>
            <div className='flex flex-col justify-between'>
                <Feature icon={"profile"} title="Créez votre compte facilement"/>
                <Feature icon={"location"} title="Trouvez les matchs à proximité de vous"/>
                <Feature icon={"calendar"} title="Trouvez les terrains disponibles"/>
            
            </div>
            <div className='hidden md:block'>
                <img src="images/features/feature_1.png" alt="" srcset="" />
            </div>
            <div className='flex flex-col justify-between'>
                <Feature icon={"add"} title="Créez votre match et composez votre équipe"/>
                <Feature icon={"users"} title="Trouvez les meilleurs joueurs et équipes"/>
                <Feature icon={"chat"} title="Chat direct"/>
            
            </div>
        </div>
        <div className={activetab ===1? 'pt-10 grid md:grid-cols-3 ':'hidden'}>
            <div className='flex flex-col justify-between'>
                <Feature icon={"star"} title="Notez les joueurs après le match"/>
                <Feature icon={"help"} title=" Renseignez-vous sur tous les avantages avant de faire votre choix"/>
                <Feature icon={"users"} title="Rejoignez une communauté des amateurs du football"/>
            </div>
              
            <div className='hidden md:block'>
                <img src="images/features/feature_2.png" alt="" srcset="" />
            </div>
            <div className='flex flex-col justify-between'>
                <Feature icon={"dollar"} title="Trouvez les meilleures offres"/>
                <Feature icon={"certif"} title="Devenez l'homme du match"/>
                <Feature icon={"notif"} title="Restez notifié de toutes les nouveautés qui vous concernent"/>
                
                
            </div>
        </div>
        <div className={activetab ===2? 'pt-10 grid md:grid-cols-3 ':'hidden'}>
            <div className='flex flex-col  justify-between'>
                <Feature icon={"time"} title="Gérer votre disponibilité et celle de votre équipe"/>
                <Feature icon={"world"} title="Créez un réseau des amateurs de footballs"/>
                <Feature icon={"support"} title="Service client disponible et à l'écoute"/>
                
            </div>
            <div className='hidden md:block'>
                <img src="images/features/feature_3.png" alt="" srcset="" />
            </div>
            <div className='flex flex-col justify-between'>
                <Feature icon={"cup"} title="Créez/participez à des tournois et gagner des prix"/>
                <Feature icon={"users"} title="Créez des groupes de chat privéx"/>
                <Feature icon={"gift"} title="Application gratuite et sans engagement"/>
               
            </div>
        </div>
        </div>
    )
}

export default Features