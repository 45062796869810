import React from 'react'

// date format = 12/31/2021

function Hero() {
  
      
    return (
        <div className='bg-center font-extrabold md:pt-0 pt-32 px-8  bg-cover bg-no-repeat' style={{backgroundImage:'url(/bg.png)'}}>
            <div style={{minHeight:"100vh"}} className="md:flex justify-evenly items-center ">
                <div className='md:w-1/2'>
                    <h1 className='lg:text-5xl md:text-4xl text-2xl md:leading-tight leading-10   text-white font-extrabold capitalize'>
                        1ʳᵉ application marocaine qui <span className='text-secondary'>rassemble</span> la communauté des amateurs de football !
                    </h1>
                    <div className='py-6'></div>
                    <div className='grid grid-cols-2 gap-4'>
                        <button className='py-4 rounded-full font-bold px-2 bg-secondary text-white'>
                            Télécharger sur Android
                        </button>
                        <button className='py-4 rounded-full font-bold px-2 bg-secondary text-white'>
    
                            Télécharger sur IOS
                        </button>
                    </div>
                

                </div>

                <div className='md:w-1/2 p-8'>
                    <img src="home.png" className='w-full' alt="" srcset="" />
                </div>


            </div>
        </div>
        
    )
}

export default Hero
