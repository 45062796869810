import React, { useRef } from 'react'

function Slider() {

  const refSlider = useRef()

  const goNext = () => {
    refSlider.current.scrollLeft += 120;
  }
  const goPrev = () => {
    refSlider.current.scrollLeft -= 120;

  }
  

  return (
    <div data-aos="fade-up" className='flex  text-white items-center bg-cover py-20 bg-no-repeat bg-center' style={{backgroundImage:'url(/bg3.png)'}}>
        <div className='p-3 '>
          <button onClick={goPrev}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 w-16">
              <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
            </svg>
          </button>
        

        </div>
        <div ref={refSlider} className='flex scroll-smooth hide-scrollbar overflow-x-scroll '>
          <div className='lg:w-1/4 md:w-1/3 w-1/2 flex-shrink-0'>
            <img className='' src="images/gallery/3.png" alt="" srcset="" />
          </div>
          <div className='lg:w-1/4 md:w-1/3 w-1/2 flex-shrink-0'>
            <img className='' src="images/gallery/4.png" alt="" srcset="" />
          </div>
          <div className='lg:w-1/4 md:w-1/3 w-1/2 flex-shrink-0'>
            <img className='' src="images/gallery/5.png" alt="" srcset="" />
          </div>
          <div className='lg:w-1/4 md:w-1/3 w-1/2 flex-shrink-0'>
            <img className='' src="images/gallery/6.png" alt="" srcset="" />
          </div>
          <div className='lg:w-1/4 md:w-1/3 w-1/2 flex-shrink-0'>
            <img className='' src="images/gallery/7.png" alt="" srcset="" />
          </div>
          <div className='lg:w-1/4 md:w-1/3 w-1/2 flex-shrink-0'>
            <img className='' src="images/gallery/8.png" alt="" srcset="" />
          </div>
          <div className='lg:w-1/4 md:w-1/3 w-1/2 flex-shrink-0'>
            <img className='' src="images/gallery/9.png" alt="" srcset="" />
          </div>

        </div>
        <div className='p-3'>
          <button onClick={goNext}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 w-16">
              <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
         
        </div>
     
    </div>
  )
}

export default Slider